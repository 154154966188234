import { React, useContext } from 'react';
import PropTypes from 'prop-types';
import { CompanyContext, ConfigContext, ConfigLoadedContext } from '../../context/context';
import useTranslation from '../../hooks/useTranslation';
import bootstrap from 'bootstrap';
import { BsInstagram, BsLinkedin, BsFacebook } from "react-icons/bs";

import styles from './Footer.module.css';

import Button from '../Button/Button';
import Logo from '../Logo/Logo';
import Icon from '../Icon/Icon';

export default function Footer({ }) {
  const config = useContext(ConfigContext);
  const configLoaded = useContext(ConfigLoadedContext);
  const company = useContext(CompanyContext);
  const t = useTranslation();

  let Footer;
  let itemsRows = [];
  let itemsSocials = [];

  // Funktion, um zu überprüfen, ob ein Link intern oder extern ist
  const isExternalLink = (url) => {
    return /^(http|https):\/\/|^www/.test(url);
  };

  if (configLoaded === true) {
    let currentRow = [];
    config.Fusszeile.forEach((item, index) => {
      if (item.header) {
        if (currentRow.length > 0) {
          itemsRows.push(currentRow);
          currentRow = [];
        }
        currentRow.push(
          <b key={"header_" + index}>
            <a href={item.link} className={styles.footerRow}>{item.name}</a>
          </b>
        );
      } else {
        currentRow.push(
          <a key={"item_" + index}
             className={styles.footerItem}
             href={item.link}
             target={isExternalLink(item.link) ? "_blank" : "_self"}>
            {item.name}
          </a>
        );
      }
    });
    if (currentRow.length > 0) {
      itemsRows.push(currentRow);
    }

    let footerItemsSocials = config.Socials;
    if (footerItemsSocials !== undefined) {
      footerItemsSocials.forEach((socialItem, index) => {
        itemsSocials.push(
          <a key={"socials_" + index} href={socialItem.link} className={styles.socialIcons} target="_blank">
            <Icon className={styles.socialIconElement} type="" icon={socialItem.type} color="white" size="20px" />
          </a>
        );
      });
    }

    Footer = (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={`${styles.footerCompany} col-sm-4 col-md-4 col-lg-2`}>
            <div className="col-sm-12 col-6">
              <Logo
                company={company}
                link="/"
                type="color-black"
                width="70%"
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className={`${styles.footerItems} col-md-2`}>
              <b className={styles.footerRow}>{config.Name}</b>
              <span className={styles.footerItem}>{config.AdresseZeile1}</span>
              <span className={styles.footerItem}>{config.AdresseZeile2}</span>
            </div>
            {itemsRows.map((row, index) => (
              <div key={"row_" + index} className={`${styles.footerItems} col-md-2`}>
                {row}
                {index === itemsRows.length - 1 && itemsSocials.length > 0 ? (
                  <div className={styles.socialIconsWrapper}>
                    {itemsSocials}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
          {company !== "digallo" && company !== "forum-gerontologie" ? (
            <a href="https://www.averecura.ch/" target='_blank'>
              <div className={styles.footerLogo}>
                <p><small>ein Betrieb der averecura holding</small></p>
                <Logo
                  company="digallo"
                  link="https://www.averecura.ch/"
                  type="color-black"
                  width="200px"
                  target="_blank"
                />
              </div>
            </a>
          ) : ""}
        </div>
        <div className={styles.footerLegal}>
          <a className={styles.footerLegalItem} href={"/impressum"}>{t.c.footer.imprint}</a>
          <a className={styles.footerLegalItem} href={"/datenschutz"}>{t.c.footer.dataProtection}</a>
        </div>
      </div>
    );
  }

  return Footer;
}

Footer.propTypes = {
  backgroundImage: PropTypes.string,
};
