import React, { useContext, useMemo } from 'react';
import { LangContext } from '../context/context';
import translationsDe from '../translations/de.json';
import translationsEn from '../translations/en.json';
import translationsIt from '../translations/it.json';
import translationsFr from '../translations/fr.json';

const translations = {
    "de": translationsDe,
    "de-CH": translationsDe,
    "en": translationsEn,
    "it": translationsIt,
    "fr": translationsFr
};

export default function useTranslation() {
    const [lang] = useContext(LangContext);

    const language = lang || 'de'; // Default to 'de' if lang is undefined

    const t = useMemo(() => {
        const currentTranslations = translations[language] || {};
        return currentTranslations;
    }, [language]);

    return t;
}
