import React from 'react';
import PropTypes from 'prop-types';

import BrunisbergB from '../../assets/logos/brunisberg/Brunisberg_B.svg';
import BrunisbergRgbB from '../../assets/logos/brunisberg/Brunisberg_RGB_B.svg';
import BrunisbergRgbW from '../../assets/logos/brunisberg/Brunisberg_RGB_W.svg';
import BrunisbergSmallRgbB from '../../assets/logos/brunisberg/Brunisberg_Small_RGB_B.svg';
import BrunisbergW from '../../assets/logos/brunisberg/Brunisberg_W.svg';

import KreuzstiftB from '../../assets/logos/kreuzstift/Kreuzstift_B.svg';
import KreuzstiftRgbB from '../../assets/logos/kreuzstift/Kreuzstift_RGB_B.svg';
import KreuzstiftRgbW from '../../assets/logos/kreuzstift/Kreuzstift_RGB_W.svg';
import KreuzstiftSmallRgbB from '../../assets/logos/kreuzstift/Kreuzstift_Small_RGB_B.svg';
import KreuzstiftW from '../../assets/logos/kreuzstift/Kreuzstift_W.svg';

import RosengartenB from '../../assets/logos/rosengarten/Rosengarten_B.svg';
import RosengartenRgbB from '../../assets/logos/rosengarten/Rosengarten_RGB_B.svg';
import RosengartenRgbW from '../../assets/logos/rosengarten/Rosengarten_RGB_W.svg';
import RosengartenSmallRgbB from '../../assets/logos/rosengarten/Rosengarten_Small_RGB_B.svg';
import RosengartenW from '../../assets/logos/rosengarten/Rosengarten_W.svg';

import TaborB from '../../assets/logos/tabor/Tabor_B.svg';
import TaborRgbB from '../../assets/logos/tabor/Tabor_RGB_B.svg';
import TaborRgbW from '../../assets/logos/tabor/Tabor_RGB_W.svg';
import TaborSmallRgbB from '../../assets/logos/tabor/Tabor_Small_RGB_B.svg';
import TaborW from '../../assets/logos/tabor/Tabor_W.svg';

import WäckerlingB from '../../assets/logos/wäckerling/Wäckerling_B.svg';
import WäckerlingRgbB from '../../assets/logos/wäckerling/Wäckerling_RGB_B.svg';
import WäckerlingRgbW from '../../assets/logos/wäckerling/Wäckerling_RGB_W.svg';
import WäckerlingSmallRgbB from '../../assets/logos/wäckerling/Wäckerling_Small_RGB_B.svg';
import WäckerlingW from '../../assets/logos/wäckerling/Wäckerling_W.svg';

import ZumiparkB from '../../assets/logos/zumipark/Zumipark_B.svg';
import ZumiparkRgbB from '../../assets/logos/zumipark/Zumipark_RGB_B.svg';
import ZumiparkRgbW from '../../assets/logos/zumipark/Zumipark_RGB_W.svg';
import ZumiparkSmallRgbB from '../../assets/logos/zumipark/Zumipark_Small_RGB_B.svg';
import ZumiparkW from '../../assets/logos/zumipark/Zumipark_W.svg';

import RivabellaB from '../../assets/logos/rivabella/Rivabella_B.svg';
import RivabellaRgbB from '../../assets/logos/rivabella/Rivabella_RGB_B.svg';
import RivabellaRgbW from '../../assets/logos/rivabella/Rivabella_RGB_W.svg';
import RivabellaSmallRgbB from '../../assets/logos/rivabella/Rivabella_Small_RGB_B.svg';
import RivabellaW from '../../assets/logos/rivabella/Rivabella_W.svg';

import ImpulsB from '../../assets/logos/impuls/Impuls_B.svg';
import ImpulsRgbB from '../../assets/logos/impuls/Impuls_RGB_B.svg';
import ImpulsRgbW from '../../assets/logos/impuls/Impuls_RGB_W.svg';
import ImpulsSmallRgbB from '../../assets/logos/impuls/Impuls_Small_RGB_B.svg';
import ImpulsW from '../../assets/logos/impuls/Impuls_W.svg';

import BaumaB from '../../assets/logos/bauma/Bauma_B.svg';
import BaumaRgbB from '../../assets/logos/bauma/Bauma_RGB_B.svg';
import BaumaRgbW from '../../assets/logos/bauma/Bauma_RGB_W.svg';
import BaumaSmallRgbB from '../../assets/logos/bauma/Bauma_Small_RGB_B.svg';
import BaumaW from '../../assets/logos/bauma/Bauma_W.svg';

import GeerenB from '../../assets/logos/geeren/Geeren_B.svg';
import GeerenRgbB from '../../assets/logos/geeren/Geeren_RGB_B.svg';
import GeerenRgbW from '../../assets/logos/geeren/Geeren_RGB_W.svg';
import GeerenSmallRgbB from '../../assets/logos/geeren/Geeren_Small_RGB_B.svg';
import GeerenW from '../../assets/logos/geeren/Geeren_W.svg';

import GerontologieRgbB from '../../assets/logos/forum/Gerontologie_RGB_B.svg';
import GerontologieSmallRgbB from '../../assets/logos/forum/Gerontologie_Small.png';

import diGalloRgbB from '../../assets/logos/averecura/averecura.svg';
import diGalloAlt from '../../assets/logos/gruppe/di_gallo-gruppe.png';
import averecura from '../../assets/logos/averecura/averecura.svg';

import styles from './Logo.module.css';

export default function Logo({ link, type, company, width, height, target = '_self' }) {
  let source = averecura;
  let name = "";

  // Set Name
    switch (company) {
      case 'digallo':
        name = "averecura holding ag";
        break;
      case 'brunisberg':
        name = "Wohn- und Altersheim Brunisberg"
        break;
      case 'kreuzstift':
        name = "Alters- und Pflegezentrum Kreuzstift"
        break;
      case 'rosengarten':
        name = "Pflege- und Betreuungszentrum Rosengarten Gossau"
        break;
      case 'tabor':
        name = "Zentrum für Langzeitpsychiatrie Tabor"
        break;
      case 'waeckerling':
        name = "Betreuungs- und Pflegeheim Wäckerling"
        break;
      case 'zumipark':
        name = "Alters- und Pflegeresidenz Zumipark"
        break;
      case 'rivabella':
        name = "Rivabella"
        break;
      case 'impuls':
        name = "Impuls Wetzikon – Wohnen mit Service"
        break;
      case 'bauma':
        name = "Pflegezentrum Bauma"
        break;
      case 'geeren':
        name = "Haus Geeren"
        break;
      case 'forum-gerontologie':
        name = "Forum Gerontologie"
        break;

    }

  if (type === 'color-white') {
    switch (company) {
      case 'digallo':
        source = diGalloRgbB;
        name = "averecura holding ag";
        break;
      case 'brunisberg':
        source = BrunisbergRgbW;
        name = "Wohn- und Altersheim Brunisberg"
        break;
      case 'kreuzstift':
        source = KreuzstiftRgbW;
        name = "Alters- und Pflegezentrum Kreuzstift"
        break;
      case 'rosengarten':
        source = RosengartenRgbW;
        name = "Pflege- und Betreuungszentrum Rosengarten Gossau"
        break;
      case 'tabor':
        source = TaborRgbW;
        name = "Zentrum für Langzeitpsychiatrie Tabor"
        break;
      case 'waeckerling':
        source = WäckerlingRgbW;
        name = "Betreuungs- und Pflegeheim Wäckerling"
        break;
      case 'zumipark':
        source = ZumiparkRgbW;
        name = "Alters- und Pflegeresidenz Zumipark"
        break;
      case 'rivabella':
        source = RivabellaRgbW;
        name = "Rivabella"
        break;
      case 'impuls':
        source = ImpulsRgbW;
        name = "Impuls Wetzikon – Wohnen mit Service"
        break;
      case 'bauma':
        source = BaumaRgbW;
        name = "Pflegezentrum Bauma"
        break;
      case 'geeren':
        source = GeerenRgbW;
        name = "Haus Geeren"
        break;
      case 'forum-gerontologie':
        source = GerontologieRgbB;
        name = "Forum Gerontologie"
        break;

    }
  } else if (type === 'small' || type === 'small-black') {
    switch (company) {
      case 'digallo':
        source = averecura;
        break;
      case 'brunisberg':
        source = BrunisbergSmallRgbB;
        break;
      case 'kreuzstift':
        source = KreuzstiftSmallRgbB;
        break;
      case 'rosengarten':
        source = RosengartenSmallRgbB;
        break;
      case 'tabor':
        source = TaborSmallRgbB;
        break;
      case 'waeckerling':
        source = WäckerlingSmallRgbB;
        break;
      case 'zumipark':
        source = ZumiparkSmallRgbB;
        break;
      case 'rivabella':
        source = RivabellaSmallRgbB;
        break;
      case 'impuls':
        source = ImpulsSmallRgbB;
        break;
      case 'bauma':
        source = BaumaSmallRgbB;
        break;
      case 'geeren':
        source = GeerenSmallRgbB;
        break;
      case 'forum-gerontologie':
        source = GerontologieSmallRgbB;
        break;
    }
  } else {
    switch (company) {
      case 'digallo':
        source = averecura;
        break;
      case 'brunisberg':
        source = BrunisbergRgbB;
        break;
      case 'kreuzstift':
        source = KreuzstiftRgbB;
        break;
      case 'rosengarten':
        source = RosengartenRgbB;
        break;
      case 'tabor':
        source = TaborRgbB;
        break;
      case 'waeckerling':
        source = WäckerlingRgbB;
        break;
      case 'zumipark':
        source = ZumiparkRgbB;
        break;
      case 'rivabella':
        source = RivabellaRgbB;
        break;
      case 'impuls':
        source = ImpulsRgbB;
        break;
      case 'bauma':
        source = BaumaRgbB;
        break;
      case 'geeren':
        source = GeerenRgbB;
        break;
      case 'forum-gerontologie':
        source = GerontologieRgbB;
        break;
    }
  }

  return (
    <a href={link} aria-label="Link zur Startseite" className={styles.button} target={target} name={"Logo "+name}>
      <img src={source} width={width} height={height} alt="" />
    </a>
  );
}

Logo.propTypes = {
  /** Link the Logo redirects to */
  link: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  /** Set Type of Logo */
  company: PropTypes.oneOf(['digallo', 'brunisberg', 'kreuzstift', 'rosengarten', 'tabor', 'waeckerling', 'zumipark', 'rivabella', 'impuls', 'bauma', 'geeren', 'forum-gerontologie', '']),
  type: PropTypes.oneOf(['color-black', 'color-white', 'small', 'small-black']),
  /** Target attribute for the link */
  target: PropTypes.string,
};
